import { treatActionsRoutes } from '../utils'

const defaultState = {
    offersList: [],
    hiredOffersList: [],
}

const state = { ...defaultState }

const routes = [
  {
    state: 'offersList',
    method: 'GET',
    route: '/offer?includeImages=true',
    action: 'getOffersList',
  },
  {
    method: 'GET',
    route: '/offer/{id}?includeImages=true',
    action: 'getOffer',
  },
  // Contract
  {
    state: 'hiredOffersList',
    method: 'GET',
    route: '/contract',
    action: 'getHiredOffersList',
  },
  {
    method: 'POST',
    route: '/contract/{offer_id}',
    action: 'hireOffer',
  },
  {
    method: 'DELETE',
    route: '/contract/{contract_id}',
    action: 'unhireOffer',
  },
]

const getters = {
    GET_FORMATTED_DESCRIPTION: () => (description) => {
        let formattedDescription = []
        description.split('\n').forEach(element => {
            const prefix = element.slice(0, 2)
            if (prefix === '- ') {
                formattedDescription.push([element.slice(2)])
            } else if (prefix === '* ') {
                formattedDescription.push({ 'bold': element.slice(2) })
            } else {
                formattedDescription.push(element)
            }
        })
        return formattedDescription
    },
    GET_FREE_OFFERS: () => {
        if (!process.env.VUE_APP_FREE_OFFERS) return []
        const freeOffers = process.env.VUE_APP_FREE_OFFERS.split(',')
        return freeOffers
    },
    GET_SUBSCRIBED_CONTRACTS: (state) => {
        return state.hiredOffersList.map(contract => {
            const offer = state.offersList.find(offer => offer.id === contract.offerId)
            return {
                ...contract,
                offer
            }
        })
    },
    GET_OFFERS_LIST: (state, getters) => {
        return state.offersList.map(item => {
            return getters.getOfferInfo(item.id)
        })
    },
    getOfferInfo: (state) => offerId => {
        const offerDetail = state.offersList.find(offer => offer.id === offerId)
        if (!offerDetail) return
        let contract = state.hiredOffersList.find(
            hiredOffer => hiredOffer.offerId === offerDetail.id
        )
        let offerInfo = {
            id: offerDetail.id,
            alias: offerDetail.alias,
            name: offerDetail.name,
            description: offerDetail.description,
            logo: offerDetail.logo || '',
            pictures: offerDetail.pictures || [],
            ranges: offerDetail.ranges || [],
            summary: offerDetail.summary,
            fixedValue: offerDetail.fixedValue,
            variableValue: offerDetail.variableValue,
            can_contract: offerDetail.can_contract,
            contract: null
        }
        if (contract) {
            offerInfo.contract = { ...contract }
            offerInfo.contract.start = Date.parse(contract.start)
            offerInfo.contract.end = contract.end ? Date.parse(contract.end) : null
        }
        if (offerDetail.items) {
            offerInfo.items = offerDetail.items
        }
        return offerInfo
    }
}

const mutations = {
    SET_STATE(state, newState) {
        Object.keys(newState).forEach(key => state[key] = newState[key])
    },
    RESET_STATE(state) {
        Object.keys(state).forEach(key => state[key] = defaultState[key])
    },
    setOfferFromOffersList(state, offerInfo) {
        let offerIndex = state.offersList.findIndex(offer => offer.id === offerInfo.id)

        if (offerIndex < 0) state.offersList.push(offerInfo)
        else state.offersList[offerIndex] = offerInfo
    }
}

const actions = {
    doResetState({ commit }) {
        commit('RESET_STATE')
    }
}

routes.forEach((item) => {
    actions[item.action] = (
        { commit },
        { body, queryString, routeParams } = { body: {}, queryString: {}, routeParams: {} }
    ) => treatActionsRoutes(
        item,
        body,
        queryString,
        routeParams,
        commit,
        defaultState,
        "offers",
        process.env.VUE_APP_PORTAL_BACKEND_URL
    )
})

export { state, getters, mutations, actions }